@import "../../styles/globals.scss";

.header {
  background-color: $mainBgColor;
  position: fixed;
  top: 0;
  left: 50%;
  width: 100vw;
  z-index: 5;
  overflow: hidden;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);

  &::after {
    content: "";
    position: absolute;
    top: 99%;
    left: 0%;
    width: 100vw;
    height: 1px;
    background-color: #AEBCD2;
  }

  :global(.container) {
    display: flex;
    justify-content: space-between;
    height: fit-content;

    &>* {
      padding: 30px 0;
    }
  }

  .menuIcon {
    font-size: 30px;
    cursor: pointer;
  }

  .logo {
    display: none;
  }

  .numberPhone {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: Inter;
    font-size: calc(20px + (24 - 20) * ((100vw - 390px) / (1920 - 390)));
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .menu {
    color: $mainBgColor;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 30px;
    position: relative;
    font-family: Inter;
    font-size: 15.125px;
    font-weight: 400;
    line-height: 19.2px;

    span {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0%;
      width: 100vw;
      height: 99%;
      background-color: rgba(0, 0, 0, 0.1);
      transition: width 0.3s ease-in-out;
      background-color: $mainColor;
      z-index: -1;
    }
  }

  :global(.wrapper) {
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
    height: fit-content;
  }

  @media (max-width: 768px) {
    :global(.container) {
      &>* {
        padding: 20px 0;
      }
    }

    .menu {
      color: $mainColor;

      span {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    .logo {
      display: block;
      width: 45px;
      height: auto;
    }

    .numberPhone {
      img {
        display: none;
      }
    }
  }
}