@import "../../../styles/globals.scss";

.carPark {
  strong {
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    text-align: center;
  }

  .description {
    color: $black;
    text-align: center;
    font-family: Oswald;
    font-size: calc(13px + (20 - 13) * ((100vw - 390px) / (1920 - 390)));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1200px) {
    .images {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }
  }
}