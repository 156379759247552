@import "../../../styles/globals.scss";

.travelRoutes {
  opacity: 0;

  :global(.container) {
    position: relative;
    display: flex;
    min-width: 0;
    gap: 60px;
  }

  .travelItem {
    h3 {
      font-family: Inter;
      font-size: 19.375px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.4px;
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px 20px;
    }

    li {
      display: inline-block;
      color: #AEBCD2;
      font-family: Inter;
      font-size: 15.5px;
      font-style: normal;
      font-weight: 300;
      line-height: 19.2px;
      /* 123.871% */
      display: flex;
      gap: 10px;
    }

    .top {
      position: relative;
      overflow: hidden;

      .price {
        position: absolute;
        right: 0;
        bottom: 50px;
        background-color: $darkBg;
        display: flex;
        align-items: center;
        color: $mainBgColor;
        font-family: Inter;
        font-size: 15.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.2px;
        padding: 24px 40px;
        cursor: pointer;
        gap: 30px;
      }

      img {
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
      }

      &:hover {
        img {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
        }
      }
    }

    .bottom {
      margin-top: 20px;

      img {
        width: 16px;
        aspect-ratio: 1/1;
        height: auto;
      }
    }
  }

  :global(.slick-slider) {
    position: static;

  }

  :global(.prevArrow),
  :global(.nextArrow) {
    position: absolute;
    bottom: 0;
  }

  :global(.prevArrow) {
    left: 0;
  }

  :global(.nextArrow) {
    left: 190px;
  }

  .text {
    height: fit-content;
  }

  :global(.sliderCounter) {
    left: calc(250px / 2);
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  :global(.slick-track) {
    display: flex;
    gap: 20px;
  }

  .slider {
    width: calc(100% - 200px);
    // height: 100%;
  }

  :global(.slick-slide) {

    &>* {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  @media (max-width: 1000px) {


    :global(.slick-track) {
      gap: 0px;
    }

    .top {
      width: 100%;
      aspect-ratio: 350/320;
      height: auto;
    }

    :global(.slick-slide) {

      img {
        object-fit: cover;
      }
    }
  }

  @media (max-width: 1500px) {
    margin-bottom: 50px;

    :global(.container) {
      display: block;
    }

    strong,
    h2 {
      text-align: center;
    }

    .slider {
      width: 100%;
      position: relative;
    }

    :global(.prevArrow),
    :global(.nextArrow) {
      position: absolute;
      top: calc(100% + 20px) !important;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }

    :global(.prevArrow) {
      left: calc(50% - 100px) // left: 50%;
    }

    :global(.nextArrow) {
      // left: 50%;
      left: calc(50% + 100px)
    }

    :global(.sliderCounter) {
      left: 50%;
      top: calc(100% + 35px) !important;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
    }
  }
}