@import "../../../styles/globals";

.reservation {
  padding: 90px 0;
  background-color: $darkBg;
  color: $mainBgColor;

  strong {
    color: $darkColor;
  }

  .reservation__subtitle {
    // font-size: 55px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;

    span {
      color: $mainColor;
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .leftColumn {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 1;

    :global(.button) {
      width: fit-content;
      // padding-left: 40px;
      // padding-right: 40px;
    }
  }

  .rightColumn {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      width: 140%;
      aspect-ratio: 3/2;
      height: auto;
    }
  }

  @media (max-width: 1200px) {
    padding: 50px 0;

    .content {
      grid-template-columns: 1fr;
    }

    strong,
    h2 {
      text-align: center;
    }

    :global(.button) {
      width: 100% !important;
    }

    .rightColumn {
      height: 400px;

      img {
        position: absolute;
        top: 50%;
        left: 80%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        width: auto;
        height: 110%;
        aspect-ratio: 3/2;
        // height: auto;
      }
    }
  }
}