@import "../../../styles/globals.scss";

.answers {

  strong,
  h2 {
    text-align: center;
  }

  .number {
    color: $mainColor;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .arrow {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    line-height: 0;
    transition: transform .5s ease;
    -webkit-transition: transform .5s ease;
    -moz-transition: transform .5s ease;
    -ms-transition: transform .5s ease;
    -o-transition: transform .5s ease;
  }

  :global(.accordionMenu) {
    font-size: 15px;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 17px;
    font-family: Inter;
    font-size: calc(13px + (19 - 13) * ((100vw - 390px) / (1920 - 390)));
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  :global(.accordionContent) {
    height: 0;
    overflow: hidden;
    color: $darkColor;
    font-size: calc(14px + (20 - 14) * ((100vw - 390px) / (1920 - 390)));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // margin-top: 20px;
  }

  :global(.accordionGroup) {
    border: 1px solid #AEBCD2;
    // padding: 30px;
    padding: calc(15px + (30 - 15) * ((100vw - 390px) / (1920 - 390)));
    cursor: pointer;
  }

  :global(.accordionGroup.active) {
    .arrow {
      transform: scale(1, -1);
      -webkit-transform: scale(1, -1);
      -moz-transform: scale(1, -1);
      -ms-transform: scale(1, -1);
      -o-transform: scale(1, -1);
    }
  }

}