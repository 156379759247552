@import "../../../styles/globals.scss";

.menu {
  position: fixed;
  top: 0;
  right: 0;
  background: $mainColor;
  padding: 50px;
  width: 475px;
  color: $mainBgColor;
  height: 100vh;
  overflow-y: auto;
  z-index: 5;


  .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  nav {
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  a {
    font-family: Oswald;
    font-size: calc(20px + (24 - 20) * ((100vw - 390px) / (1920 - 390)));
    font-weight: 500;
    line-height: 28.8px;
    text-transform: uppercase;
  }

  .contacts {
    display: flex;
    flex-direction: column;

    strong {
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.2px;
      letter-spacing: 8px;
      text-transform: uppercase;
      margin-top: 20px;
      margin-bottom: 0;
    }

    .numberPhone {
      font-family: Inter;
      font-size: 14.625px;
      font-weight: 400;
      line-height: 19.2px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .socials {
      display: flex;
      gap: 10px;
      margin-top: 10px;

      a {
        display: flex;
        // height: fit-content;

      }

      img {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;

    nav {
      margin-top: 20px;
      padding: 20px 0;
      border-top: 1px solid #FFF;
      border-bottom: 1px solid #FFF;
    }
  }
}