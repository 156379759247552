@import "../../../styles/globals.scss";

.orderRidePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);
  backdrop-filter: blur(5px);
  z-index: 6;
  padding: 20px;
  overflow-y: auto;

  .close {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    justify-content: flex-end;
    font-size: 30px;
  }

  .orderRidePopup__content {
    position: relative;
    width: 100%;
    border: 1px solid $darkBg;
    background: #EFEFEF;
    max-width: 700px;
    margin: 5vh auto;
    padding: calc(50px + (100 - 50) * ((100vw - 390px) / (1920 - 390))) calc(30px + (100 - 30) * ((100vw - 390px) / (1920 - 390))) 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    h2 {
      text-align: center;
      margin-bottom: 0;
    }

    strong {
      text-align: center;
      margin-bottom: 0;
    }
  }

  input {
    position: relative;
    overflow: visible;
  }

  svg {
    max-width: 25px;
    min-width: 25px;
    aspect-ratio: 1/1;
    height: auto;
  }

  // ::-webkit-calendar-picker-indicator {
  //   // opacity: 0;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: contain;
  //   width: 20px;
  //   height: 20px;
  //   font-size: 30px;
  //   z-index: 1;
  //   position: absolute;
  //   left: -30px;
  // }
  .dateLabel {
    ::-webkit-calendar-picker-indicator {
      // opacity: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 20px;
      font-size: 30px;
      z-index: 1;
      position: absolute;
      left: 0px;
      // background: red;
    }
  }

  .hidden {
    position: absolute;
    top: 50%;
    opacity: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  button:disabled {
    opacity: 0.3;
  }
}