@import "../../styles/globals.scss";

.button {
  padding: 24px 15px;
  background-color: $mainColor;
  color: $mainBgColor;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Inter;
  // font-size: 24px;
  font-size: calc(18px + (24 - 18) * ((100vw - 390px) / (1920 - 390)));
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
  /* 80% */

  &.darkBg {
    background-color: $darkBg;
  }
}