.mainContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: calc(50px + (100 - 50) * ((100vw - 390px) / (1920 - 390)));
}

.success {
  color: green;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.1);
}