@font-face {
  font-family: 'Oswald';
  src: url(./fonts/Oswald-VariableFont_wght.eot);
  src: url(./fonts/Oswald-VariableFont_wght.eot?#iefix) format('embedded-opentype'),
    url(./fonts/Oswald-VariableFont_wght.otf) format('otf'),
    url(./fonts/Oswald-VariableFont_wght.svg#Oswald-VariableFont_wght) format('svg'),
    url(./fonts/Oswald-VariableFont_wght.ttf) format('truetype'),
    url(./fonts/Oswald-VariableFont_wght.woff2) format('woff2');
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter-VariableFont_slnt,wght.eot);
  src: url(./fonts/Inter-VariableFont_slnt,wght.eot) format('embedded-opentype'),
    url(./fonts/Inter-VariableFont_slnt,wght.otf) format('otf'),
    url(./fonts/Inter-VariableFont_slnt,wght.svg#Inter-VariableFont_slnt,wght) format('svg'),
    url(./fonts/Inter-VariableFont_slnt,wght.ttf) format('truetype'),
    url(./fonts/Inter-VariableFont_slnt,wght.woff2) format('woff2');
}

$mainColor: #FF6B00;
$mainBgColor: #FFFFFF;
$darkBg: #2E4044;
$darkColor: #5C7A81;
$black: #000000;


html {
  color: $darkBg;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
}

* {
  font-family: "Oswald", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: calc(38px + (96 - 38) * ((100vw - 390px) / (1920 - 390)));
  font-style: normal;
  font-weight: 600;
  line-height: calc(53.5px + (107 - 53.5) * ((100vw - 390px) / (1920 - 390)));
  letter-spacing: calc(1px + (3.9 - 1) * ((100vw - 390px) / (1920 - 390)));
  letter-spacing: 3.84px;
  text-transform: uppercase;
}

h2 {
  font-size: 45px;
  font-size: calc(32px + (45 - 32) * ((100vw - 390px) / (1920 - 390)));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;

  span {
    color: $mainColor;
  }
}

strong {
  font-family: Inter;
  font-size: calc(12px + (16 - 12) * ((100vw - 390px) / (1920 - 390)));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: calc(2px + (8 - 2) * ((100vw - 390px) / (1920 - 390)));
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
}

button {
  display: block;
  border: none;
  background: none;
}

ul,
ol {
  list-style: none;
}

input,
textarea {
  border: none;
  outline: none;
  background: none;
}

textarea {
  background-color: $darkBg;
  padding: 30px;
  display: block;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $mainBgColor;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
}

label {
  background-color: $darkBg;
  padding: 30px;
  display: block;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $mainBgColor;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
  position: relative;

  @media (max-width: 768px) {
    // label {
    padding: 15px 20px;
    // }
  }

  input {
    width: 100%;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  img {
    width: calc(20px + (34 - 20) * ((100vw - 390px) / (1920 - 390)));
    aspect-ratio: 1/1;
    height: auto;
  }

  ::-webkit-input-placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder,
  ::placeholder,
  ::-moz-placeholder,
  :-moz-placeholder {
    color: $darkColor;
    font-family: Inter;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
  }
}

.container {
  width: calc(320px + (1290 - 320) * ((100vw - 390px) / (1920 - 390)));
  max-width: 1290px;
  margin: 0 auto;
}

.prevArrow,
.nextArrow {
  //position: absolute;
  //top: calc(100% + 20px);
  width: 60px;
  height: 60px;
  background: $darkBg;
  color: $mainBgColor;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    color: inherit;
    display: block;
    height: fit-content;
  }
}

.sliderCounter {
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  position: absolute;
}

.errorMess {
  color: red;
}