@import "../../../styles/globals.scss";

.findOutThePricePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0 0 0 / 50%);
  backdrop-filter: blur(5px);
  z-index: 6;
  padding: 20px;
  overflow-y: auto;

  .close {
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    justify-content: flex-end;
    font-size: 30px;
  }

  .findOutThePricePopup__content {
    position: relative;
    width: 100%;
    border: 1px solid $darkBg;
    background: #EFEFEF;
    max-width: 700px;
    margin: 5vh auto;
    padding: calc(50px + (100 - 50) * ((100vw - 390px) / (1920 - 390))) calc(30px + (100 - 30) * ((100vw - 390px) / (1920 - 390))) 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    h2 {
      text-align: center;
      margin-bottom: 0;
    }

    .socials {
      display: flex;
      justify-content: center;
      color: black;
      font-size: 25px;
      gap: 15px;
    }

    .tel {
      font-family: Inter;
      font-size: 48px;
      font-size: calc(24px + (48 - 24) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
      text-align: center;
      white-space: nowrap;
    }

    p {
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19.2px;
    }

    h3 {
      font-family: Oswald;
      font-size: calc(24px + (36 - 24) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 500;
      line-height: 28.8px;
      text-align: center;
      text-transform: uppercase;

      span {
        color: $mainColor;
      }
    }

    .subtitle {
      text-align: center;
      font-family: Oswald;
      font-size: calc(12px + (24 - 12) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 500;
      line-height: calc(14.4px + (28.8 - 14.4) * ((100vw - 390px) / (1920 - 390)));
      text-transform: uppercase;
    }
  }

  button:disabled {
    opacity: 0.3;
  }

  @media (max-width: 768px) {
    .findOutThePricePopup__content {
      gap: 15px;
    }
  }
}