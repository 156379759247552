@import "../../../styles/globals.scss";

.reviews {
  margin-bottom: 100px;
  position: relative;

  h2 {
    text-align: center;
  }

  :global(.sliderCounter) {
    top: calc(100% + 35px);
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
  }

  :global(.prevArrow),
  :global(.nextArrow) {
    position: absolute;
    top: calc(100% + 20px);
  }

  :global(.prevArrow) {
    left: calc(50% - 110px);
  }

  :global(.nextArrow) {
    right: calc(50% - 110px);
  }

  :global(.slick-slider) {
    position: relative;
  }

  :global(.slick-track) {
    display: flex;
    gap: 20px;
  }

  :global(.slick-slide) {
    // height: 900px;

    & > * {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
.reviewsWrapper {
  p {
    text-align: center;
  }
}
