@import "../../styles/globals.scss";

.footer {
  margin-top: 100px;
  position: relative;

  .mapIframe {
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .contacts {
    position: relative;
    z-index: 1;
    background: rgba(56, 82, 85, 0.90);
    color: $mainBgColor;
    width: fit-content;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 40px;
    max-width: 600px;
    min-height: 550px;

    img {
      width: calc(30px + (50 - 30) * ((100vw - 390px) / (1920 - 390)));
      aspect-ratio: 1/1;
      height: auto;
    }

    h2 {
      display: block;
      text-align: center;
      width: 100%;
      font-size: calc(32px + (55 - 32) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    a {
      color: $mainBgColor;
      font-family: Oswald;
      font-size: calc(24px + (36 - 24) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }

    .social {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .email {
      display: block;
      color: $mainBgColor;
      font-family: Oswald;
      // font-size: 32px;
      font-size: calc(24px + (32 - 24) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;

      span {
        color: $mainBgColor;
        font-family: Oswald;
        // font-size: 48px;
        font-size: calc(24px + (48 - 24) * ((100vw - 390px) / (1920 - 390)));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .address {
      color: $mainBgColor;
      font-family: Oswald;
      // font-size: 32px;
      font-size: calc(24px + (32 - 24) * ((100vw - 390px) / (1920 - 390)));
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;

      span {
        color: $mainColor;
        font-family: Oswald;
        // font-size: 48px;
        font-size: calc(24px + (48 - 24) * ((100vw - 390px) / (1920 - 390)));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: 1000px) {
    background-color: rgba(56, 82, 85, 0.90);


    .mapIframe {
      position: relative;
      min-height: 400px;
    }

    .contacts {
      padding: 40px 0;
      max-width: 100%;
      width: 100%;
      background-color: transparent;
      min-height: fit-content;
    }
  }

}