@import "../../../styles/globals.scss";

.about {
  :global(.container) {

    &>strong,
    &>h2 {
      text-align: center;
      // margin-bottom: 20px;
    }

    &>h2 {
      text-align: center;
      // font-family: Oswald;
      // font-size: 45px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .about__text {
    color: $black;
    text-align: center;
    font-family: Oswald;
    font-size: calc(13px + (20 - 13) * ((100vw - 390px) / (1920 - 390)));
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;

    &::before {
      display: none;
      content: "";
      position: absolute;
      width: 120px;
      aspect-ratio: 1/1;
      left: 0;
      bottom: 0;
      z-index: -1;
      background-image: url("../../../../public/assets/icons/bus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      opacity: 0.5;
      transform: translateY(55%);
      -webkit-transform: translateY(55%);
      -moz-transform: translateY(55%);
      -ms-transform: translateY(55%);
      -o-transform: translateY(55%);
    }
  }

  .content {
    display: grid;
    grid-template-columns: 370px 1fr;
    gap: 60px;
    margin-top: 60px;
  }

  .leftColumn {
    h2 {
      color: $black;
      // font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .rightColumn {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
  }

  .benefit {
    background-color: $darkBg;
    color: $mainBgColor;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 50px 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1700px) {

    strong,
    h2 {
      text-align: center;
    }

    .content {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .rightColumn {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }
  }

  @media (max-width: 768px) {
    .about__text {
      &::before {
        display: block;
      }
    }

    .benefit {
      padding: 20px 10px;
    }

    .rightColumn {
      gap: 15px;
    }
  }
}