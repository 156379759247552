.banner {
  // min-height: 920px;
  // background: red;
  position: relative;
  padding-top: 100px;
  opacity: 0;

  h1 {
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  }

  .bannerImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right center;
    opacity: calc(0.2 + (1 - 0.2) * ((100vw - 390px) / (1920 - 390)));

    // img {
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }
  }

  :global(.container) {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: calc(30px + (200 - 30) * ((100vw - 390px) / (1920 - 390))) 0;
  }

  .subtitle {
    font-family: Oswald;
    font-size: calc(20px + (35 - 20) * ((100vw - 390px) / (1920 - 390)));
    font-style: normal;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    position: relative;
    top: -20px;
  }

  .buttons {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    width: 100%;
  }

  .mainInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;

  }

  @media (max-width: 1300px) {
    :global(.container) {
      grid-template-columns: 1fr;
    }

    .buttons {
      margin-top: 0;
    }
  }

  @media (max-width: 768px) {

    h1,
    .subtitle {
      text-align: center;
      width: 100%;
    }

    .bannerImg {
      opacity: 0;
    }

    .mainInfo {
      gap: 10px;
    }

    .buttons {
      grid-template-columns: 1fr;
      gap: 10px;
    }

  }
}